import { css } from 'styled-components';

// *** Theme Styling *** //
// --------------
// 1. Brand Colours
// --------------
// 2. Social Colours
// --------------
// 3. Notification Colours
// --------------
// 4. Padding Sizes
// --------------
// 5. Margin Sizes
// --------------
// 6. Fonts
// --------------
// 7. Breakpoints
// --------------
// 8. Cubic Bezier
// --------------
// 9. Magic Number
// --------------
// 10. Mixins
// --------------

export const gridBreakpoints = {
	small: 0,
	smedium: 370,
	medium: 768,
	large: 1024,
	xlarge: 1200,
	xxlarge: 1440,
	huge: 1600,
	uber: 2000,
}

// Theme Styles
export const themeStyles = {
	// 1. Social Colours
	// --------------
	facebook: '#3b5998',
	twitter: '#1da1f2',
	creativeMarket: '#8ba753',
	slack: '#e9a820',
	skype: '#00aff0',
	instagram: '#833ab4',
	dribbble: '#ea4c89',
	behance: '#1769ff',
	linkedin: '#0077b5',

	// 2. Notification Colours
	// --------------
	pos: '#3adb76', // Green (Success / Positive)
	neg: '#cc4b37', // Red (Error / Negative)
	warn: '#ffae00', // Yellow (Warning / Neutral)

	// 3. Brand Colours
	// --------------
	black: '#000000',
	white: '#ffffff',
	bc1: '#87f0e6', // Light Blue
	bc2: '#df8353', // Brown
	bc3: '#623199', // Bright Purple


	// 4. Padding Sizes
	// --------------
	mpad: '1.2rem', // Mobile Padding
	padS: '6rem', // Small
	padM: '10rem', // Medium
	padL: '15rem', // Large
	padH: '25rem', // Huge

	// 5. Margin Sizes
	// --------------
	marS: '6rem', // Small
	marM: '10rem', // Medium
	marL: '15rem', // Large
	marH: '25rem', // Large

	// 6. Fonts
	// --------------
	heading: "'Reem Kufi', 'Arial', Serif;",
	body: "'Reem Kufi', 'Arial', Sans-Serif;",
	nav: "'Playfair Display', Serif",

	// 7. Grid
	// --------------
	small: gridBreakpoints.small + 'px',
	smedium: gridBreakpoints.smedium + 'px',
	medium: gridBreakpoints.medium + 'px',
	large: gridBreakpoints.large + 'px',
	xlarge: gridBreakpoints.xlarge + 'px',
	xxlarge: gridBreakpoints.xxlarge + 'px',
	huge: gridBreakpoints.huge + 'px',
	uber: gridBreakpoints.uber + 'px',

	gutterSmall: '24px',
	gutterLarge: '36px',
	maxGridSize: '1440px',

	// 8. Cubic Bezier
	// --------------
	bezzy: 'cubic-bezier(0.8, 0, 0.2, 1)',

	// 9. Magic Number
	// --------------
	magic: '1.2rem',
};

export const darkTheme = {
	// 1. Brand Colours
	// --------------
	black: themeStyles.black,
	white: themeStyles.white,
	bc1: themeStyles.bc1,
	bc2: themeStyles.bc3,
	bc3: themeStyles.bc2,


	// 2. Social Colours
	// --------------
	facebook: themeStyles.facebook,
	twitter: themeStyles.twitter,
	creativeMarket: themeStyles.creativeMarket,
	slack: themeStyles.slack,
	skype: themeStyles.skype,
	instagram: themeStyles.instagram,
	dribbble: themeStyles.dribbble,
	behance: themeStyles.behance,
	linkedin: themeStyles.linkedin,

	// 3. Notification Colours
	// --------------
	pos: themeStyles.pos, // Green (Success / Positive)
	neg: themeStyles.neg, // Red (Error / Negative)
	warn: themeStyles.warn, // Yellow (Warning / Neutral)

	// 4. Padding Sizes
	// --------------
	mpad: themeStyles.mpad, // Mobile Padding
	padS: themeStyles.padS, // Small
	padM: themeStyles.padM, // Medium
	padL: themeStyles.padL, // Large
	padH: themeStyles.padH, // Huge

	// 5. Margin Sizes
	// --------------
	marS: themeStyles.marS, // Small
	marM: themeStyles.marM, // Medium
	marL: themeStyles.marL, // Large
	marH: themeStyles.marH, // Huge

	// 6. Fonts
	// --------------
	heading: themeStyles.heading,
	body: themeStyles.body,
	nav: themeStyles.nav,

	// 7. Grid
	// --------------
	small: themeStyles.small,
	smedium: themeStyles.smedium,
	medium: themeStyles.medium,
	large: themeStyles.large,
	xlarge: themeStyles.xlarge,
	xxlarge: themeStyles.xxlarge,
	huge: themeStyles.huge,
	uber: themeStyles.uber,

	gutterSmall: themeStyles.gutterSmall,
	gutterLarge: themeStyles.gutterLarge,
	maxGridSize: themeStyles.maxGridSize,

	// 8. Cubic Bezier
	// --------------
	bezzy: themeStyles.bezzy,

	// 9. Magic Number
	// --------------
	magic: themeStyles.magic,

	// 10. Mixins
	// --------------
	// Vertical Align
	valign: css`
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
	`,

	// Vertical Align Cancel
	valignCancel: css`
		position: relative;
		top: auto;
		transform: none;
		width: auto;
	`,

	// Total Align
	talign: css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	`,

	// Total Align Cancel
	talignCancel: css`
		position: relative;
		top: auto;
		left: auto;
		transform: none;
	`,

	// No Scrollbars
	noscrollbars: css`
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none;  /* IE 10+ */

		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			background: transparent; /* Chrome/Safari/Webkit */
		}
	`,

	// Magic Cubic Bezier Animation
	cubic: (time, bezier) => css`
		transition: all ${time} ${bezier};
	`,

	// Simplified Animation
	animate: (time, ease) => css`
		transition: all ${time} ${ease};
	`,

	// Simplified Animation Delay
	delay: time => css`
		transition-delay: ${time};
	`,

	// Icon Sizer
	sizer: size => css`
		width: ${size};
		height: ${size};
	`,
}

export const lightTheme = {
	// 1. Brand Colours
	// --------------
	black: themeStyles.black,
	white: themeStyles.white,
	bc1: themeStyles.bc1,
	bc2: themeStyles.bc2,
	bc3: themeStyles.bc3,

	// 2. Social Colours
	// --------------
	facebook: themeStyles.facebook,
	twitter: themeStyles.twitter,
	creativeMarket: themeStyles.creativeMarket,
	slack: themeStyles.slack,
	skype: themeStyles.skype,
	instagram: themeStyles.instagram,
	dribbble: themeStyles.dribbble,
	behance: themeStyles.behance,
	linkedin: themeStyles.linkedin,

	// 3. Notification Colours
	// --------------
	pos: themeStyles.pos, // Green (Success / Positive)
	neg: themeStyles.neg, // Red (Error / Negative)
	warn: themeStyles.warn, // Yellow (Warning / Neutral)

	// 4. Padding Sizes
	// --------------
	mpad: themeStyles.mpad, // Mobile Padding
	padS: themeStyles.padS, // Small
	padM: themeStyles.padM, // Medium
	padL: themeStyles.padL, // Large
	padH: themeStyles.padH, // Huge

	// 5. Margin Sizes
	// --------------
	marS: themeStyles.marS, // Small
	marM: themeStyles.marM, // Medium
	marL: themeStyles.marL, // Large
	marH: themeStyles.marH, // Huge

	// 6. Fonts
	// --------------
	heading: themeStyles.heading,
	body: themeStyles.body,
	nav: themeStyles.nav,

	// 7. Grid
	// --------------
	small: themeStyles.small,
	smedium: themeStyles.smedium,
	medium: themeStyles.medium,
	large: themeStyles.large,
	xlarge: themeStyles.xlarge,
	xxlarge: themeStyles.xxlarge,
	huge: themeStyles.huge,
	uber: themeStyles.uber,

	gutterSmall: themeStyles.gutterSmall,
	gutterLarge: themeStyles.gutterLarge,
	maxGridSize: themeStyles.maxGridSize,

	// 8. Cubic Bezier
	// --------------
	bezzy: themeStyles.bezzy,

	// 9. Magic Number
	// --------------
	magic: themeStyles.magic,

	// 10. Mixins
	// --------------
	// Vertical Align
	valign: css`
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
	`,

	// Vertical Align Cancel
	valignCancel: css`
		position: relative;
		top: auto;
		transform: none;
		width: auto;
	`,

	// Total Align
	talign: css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	`,

	// Total Align Cancel
	talignCancel: css`
		position: relative;
		top: auto;
		left: auto;
		transform: none;
	`,


	// No Scrollbars
	noscrollbars: css`
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none;  /* IE 10+ */

		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			background: transparent; /* Chrome/Safari/Webkit */
		}
	`,

	// Magic Cubic Bezier Animation
	cubic: (time, bezier) => css`
		transition: all ${time} ${bezier};
	`,

	// Simplified Animation
	animate: (time, ease) => css`
		transition: all ${time} ${ease};
	`,

	// Simplified Animation Delay
	delay: time => css`
		transition-delay: ${time};
	`,

	// Icon Sizer
	sizer: size => css`
		width: ${size};
		height: ${size};
	`,
}