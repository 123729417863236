// Imports
// ------
import React from 'react';
import Helmet from 'react-helmet';
import schemaImage from '@images/schema-image.jpg'
import socialImage from '@images/social-image.jpg';

// Component
// ------
const SEO = ({ title, desc, keywords }) => {
    const schemaOrgWebPage = {
        '@context': "https://schema.org",
        '@type': "ProfessionalService",
        '@id': "https://raeandeve.com",
        name: title,
        image: schemaImage,
        url: "https://raeandeve.com",
        telephone: "+44 (0)1274 752287",
        priceRange: "$$",
        address: {
            "@type": "PostalAddress",
            "streetAddress": "Hub 26, Hunsworth Lane",
            "addressLocality": "Cleckheaton",
            "postalCode": "BD19 4LN",
            "addressCountry": "GB"
        },
        geo: {
            "@type": "GeoCoordinates",
            "latitude": 53.7356664,
            "longitude": -1.719526
        },
        openingHoursSpecification: {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday"
            ],
            "opens": "09:00",
            "closes": "17:30"
        },
        sameAs: [
            "https://facebook.com/",
            "https://twitter.com/",
            "https://instagram.com/",
            "https://youtube.com/"
        ]
    }

    return (
        <Helmet title={title}>
            <html lang="en" />
            <meta name="description" content={desc} />
            <meta name="keywords" content={keywords}/>

            <meta name="author" content={title} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={desc} />
            <meta property="og:image" content={socialImage} />

            <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
            <script>
                {`
                (function (o, u, t, b, a, s, e) {
                    window[b] = window[b] || {}; (e = e || [])['key'] = o; e['__obr'] = u.location.href; a = [];
                    u.location.search.replace('?', '').split('&').forEach(function (q) { if (q.startsWith(b) || q.startsWith('_obid')) e[q.split('=')[0]] = q.split('=')[1]; });
                    e['_obid'] = e['_obid'] || (u.cookie.match(/(^|;)\s*_obid\s*=\s*([^;]+)/) || []).pop() || 0;
                    for (k in e) { if (e.hasOwnProperty(k)) a.push(encodeURIComponent(k) + '=' + encodeURIComponent(e[k])); }
                    s = u.createElement('script'); s.src = t + '?' + a.join('&'); u.head.appendChild(s);
                })('f6c62055-8fad-47bd-9b3c-e1919d43acd9', document, 'https://plugin.sopro.io/hq.js', 'outbase')
                `}
            </script>
        </Helmet>
    )
}

export default SEO;