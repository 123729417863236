// Styles
// ------
import '@styles/app.scss';
import { ThemeProvider } from 'styled-components';
import { gridBreakpoints, lightTheme } from '@theme/theme';

// Imports
// ------
import React from 'react';
import PropTypes from 'prop-types';
import SEO from '@layouts/seo';
import { BreakpointProvider, setDefaultBreakpoints } from 'react-socks';

// Breakpoints
// ------
setDefaultBreakpoints([
	{ small: gridBreakpoints.small },
	{ smedium: gridBreakpoints.smedium },
	{ medium: gridBreakpoints.medium },
	{ large: gridBreakpoints.large },
	{ xlarge: gridBreakpoints.xlarge },
	{ xxlarge: gridBreakpoints.xxlarge },
	{ huge: gridBreakpoints.huge },
	{ uber: gridBreakpoints.uber },
]);

// Component
// ------
class Layout extends React.Component {
	render() {
		if (typeof window !== "undefined") {
			// eslint-disable-next-line global-require
			const smoothAF = require("smooth-scroll");
			new smoothAF('a[href*="#"]', { speed: 150 });
		}

		return (
			<>
				<SEO
					title={`Rae & Eve • Film & Visual content production`}
					desc={`RAE & EVE exists to bring quality film production and visual content to brands through the finest visionary talent and creative thinkers.`}
					keywords={`Film, Film Production, Videography, Visual Content, Creative, Video`}
				/>

				<BreakpointProvider>
					<ThemeProvider theme={lightTheme}>
						{this.props.children}
					</ThemeProvider>
				</BreakpointProvider>
			</>
		);
	}
}

export default Layout;


Layout.defaultProps = {
	children: null,
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};
